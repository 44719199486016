var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AdminMaintenance" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-30" },
        [
          _c("label", { staticClass: "fc-mid fs-16" }, [
            _vm._v(_vm._s(this.on))
          ]),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              on: { click: _vm.toggleMaintenanceMode }
            },
            [
              _vm._v(
                "\n      Turn " + _vm._s(this.on ? "Off" : "On") + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "base-textarea",
        {
          staticClass: "mb-30",
          attrs: {
            label: true,
            "label-for": "message",
            placeholder: "Place your custom maintenance message here..."
          },
          model: {
            value: _vm.message,
            callback: function($$v) {
              _vm.message = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "message"
          }
        },
        [_vm._v("\n    Maintenance Message\n  ")]
      ),
      _c(
        "div",
        { staticClass: "row row--justify-end" },
        [
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              on: { click: _vm.updateMaintenanceMessage }
            },
            [_vm._v("\n      Update Message\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }