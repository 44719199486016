<template>
  <div class="AdminMaintenance">
    <div class="row row--align-center row--justify-between mb-30">
      <label class="fc-mid fs-16">{{ this.on }}</label>

      <base-button
        @click="toggleMaintenanceMode"
        class="bg-blue fc-white"
      >
        Turn {{ this.on ? 'Off' : 'On' }}
      </base-button>
    </div>


    <base-textarea
      v-model.trim="message"
      class="mb-30"
      :label="true"
      :label-for="'message'"
      :placeholder="'Place your custom maintenance message here...'"
    >
      Maintenance Message
    </base-textarea>

    <div class="row row--justify-end">
      <base-button
        @click="updateMaintenanceMessage"
        class="bg-blue fc-white"
      >
        Update Message
      </base-button>
    </div>
  </div>
</template>

<script>
// Helpers
import { Tenant } from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseTextarea from '../../components/base-textarea.vue'

export default {
  name: 'AdminMaintenance',

  components: {
    BaseButton,
    BaseTextarea,
  },

  async created () {
    await this.getMaintenanceMode()
  },

  data () {
    return {
      on: false,
      message: null,
    }
  },

  methods: {
    async getMaintenanceMode () {
      const maintenanceMode = (await Tenant.fetchMaintenanceMode()).data
      this.on = maintenanceMode.maintenance_mode
      this.message = maintenanceMode.maintenance_reason
    },

    async toggleMaintenanceMode () {
      try {
        this.progressStart()
        await Tenant.updateMaintenanceMode({
          maintenance_mode: !this.on,
        })
        await this.getMaintenanceMode()
        this.progressFinish()
      }
      catch (error) {
        console.error(error)
        this.progressFail()
      }
    },

    async updateMaintenanceMessage () {
      try {
        this.progressStart()
        await Tenant.updateMaintenanceMode({
          maintenance_reason: this.message,
        })
        await this.getMaintenanceMode()
        this.progressFinish()
      }
      catch (error) {
        console.error(error)
        this.progressFail()
      }
    },
  },
}
</script>
